.heading {
  margin: 0;
}

.property {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
}

.name {
  max-width: 100px;
  width: 20%;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 22px;
  text-align: left;
}

.value {
  display: block;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 22px;
  width: 80%;
  max-width: 400px;
}

.anchor {
  color: var(--color-medium-blue);
  background: url('../DidDocument/icon_link.svg') no-repeat right 40%;
  padding-right: 20px;
}

.valid {
  composes: value;
  background: url('../../images/icon_ok.svg') no-repeat;
}

.invalid {
  composes: value;
  background: url('../../images/icon_attention.svg') no-repeat;
}
