.container {
  display: flex;
  width: 100vw;
  flex-direction: column;
}

.primary {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 76px;
  background:
    url('PrimaryHeaderLeftBubbles.svg') no-repeat left 18px / auto 100%,
    url('PrimaryHeaderRightBubbles.svg') no-repeat right/auto 110%,
    var(--color-dark-purple);
}

.logoContainer {
  max-width: 766px;
  width: 90%;
}

.logoButton {
  display: block;
  width: 168.5px;
  height: 52px;
  border: none;
  background: url('logo_DIDsign.svg') no-repeat;
  cursor: pointer;
}

.secondary {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 30px;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background: rgb(var(--color-header-below) / 90%) no-repeat;
}

.text {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 766px;
  width: 90%;
  color: var(--color-light-blue);
  font-size: 14px;
  margin-bottom: 5px;
  letter-spacing: 0.1px;
  line-height: 16px;
}

@media (width <= 600px) {
  .text {
    visibility: hidden;
  }
}
