.container {
  composes: container from '../Files/Files.module.css';
  grid-template: 'heading list' / 15% auto;
}

.heading {
  composes: heading from '../Files/Files.module.css';
  grid-area: heading;
  color: var(--color-grey);
}

.list {
  composes: list from '../Files/Files.module.css';
  grid-area: list;
  display: flex;
  flex-direction: column;
  gap: 45px;
  padding-top: 40px;
}

.emptyLines {
  border: dotted 0.2px #4d6b85;
  opacity: 0.8;
}
