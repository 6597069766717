.container {
  composes: container from '../DidDocument/DidDocument.module.css';
  margin-bottom: 30px;
}

.wrapper {
  composes: textWrapper from '../DidDocument/DidDocument.module.css';
}

.verificationText {
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.11px;
  width: 80px;
  padding-right: 35px;
  background: url('../../images/icon_attention.svg') no-repeat right 30%;
  color: var(--color-orange);
}

.title {
  composes: errorTitle from '../DidDocument/DidDocument.module.css';
}

.text {
  composes: errorText from '../DidDocument/DidDocument.module.css';
}
