.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: fit-content;
  width: 100%;
  border-bottom: 1px dotted var(--color-dark-purple);
}

.endpointTypeContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.endpoint {
  color: var(--color-dark-purple);
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 22px;
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
}

.endpointUrl {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  max-width: 400px;
  overflow-wrap: break-word;
}

.fetchButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  margin-left: auto;
  font-size: 12px;
  border: none;
  line-height: 13px;
  width: 130px;
  height: 22px;
  border-radius: 6px;
  color: white;
  background:
    url('chevron_down_white.svg') no-repeat 90% / auto,
    var(--color-medium-blue);
  cursor: pointer;
}

.closeButton {
  composes: fetchButton;
  background:
    url('chevron_up_white.svg') no-repeat 90% / auto,
    var(--color-medium-blue);
}

.loader {
  background:
    url('spinning-circles.svg') no-repeat 10%/10%,
    url('chevron_up_white.svg') no-repeat 90% / auto,
    var(--color-medium-blue);
}

.spinner {
  display: absolute;
  height: 15px;
  width: 12px;
  top: 5px;
  left: 5px;
  background: url('spinning-circles.svg') no-repeat left/100%;
}

.credentials {
  list-style: none;
  margin: 0;
  padding: 0;
}

.credential {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 5px;
  width: 100%;
  gap: 10px;
  margin-bottom: 18px;
  border-bottom: 1px dotted rgba(var(--color-pale-blue-rgb) / 25%);
  padding-bottom: 18px;
}

.credential:last-child {
  padding: 0;
  border: none;
}

.error {
  composes: credential;
}

.collapse {
  align-self: flex-end;
  display: inline-flex;
  border: none;
  background: url('chevron_up_blue.svg') no-repeat center right;
  color: var(--color-medium-blue);
  cursor: pointer;
  font-size: 12px;
  font-family: Overpass, sans-serif;
  margin-bottom: 8px;
  padding-right: 12px;
}
