.footer {
  width: 100%;
  display: flex;
  place-content: center;
  min-height: 34px;
  background: var(--color-dark-purple);
  border-top: 1px solid var(--color-silver-blue);
}

.content {
  max-width: 766px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

@media (width <= 700px) {
  .content {
    flex-direction: column;
    width: 100%;
  }
}

.navMenu {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (width <= 700px) {
  .navMenu {
    width: 100%;
    display: flex;
    place-content: center;
    padding: 7px 0;
    border-bottom: 1px solid rgb(var(--color-line-rgb) / 75%);
  }
}

.navLink {
  color: var(--color-nav-link);
  text-decoration: none;
  line-height: 20px;
}

.navLinkActive {
  composes: navLink;
  opacity: 0.5;
}

.copyright {
  color: var(--color-white);
  padding: 7px 90px 0 0;
  line-height: 20px;
  font-size: 10px;
  margin: 0;
  background: url('built-on-KILT.svg') no-repeat center right;
}

@media (width <= 700px) {
  .copyright {
    margin: 0 0 7px;
  }
}
