.container {
  box-sizing: border-box;
  min-height: 45px;
  padding: 5px 2px 5px 25px;
  border-bottom: dotted 2px var(--color-medium-blue);
}

.timestamp {
  min-height: 34px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  border-radius: 2px;
  padding-right: 6px;
  background: rgb(var(--color-orange-rgb) / 20%);
}

@media (width <= 850px) {
  .timestamp {
    flex-direction: column;
    padding: 5px;
  }
}

.heading {
  margin: 0;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  padding-left: 36px;
  min-height: 34px;
  background: url('timestamp.svg') no-repeat center left 5px;
}

.section {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  gap: 20px;
}

@media (width <= 750px) {
  .section {
    flex-direction: column;
    gap: 5px;
  }
}

.fee {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
}

.button {
  width: 100px;
  height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--color-orange);
  color: var(--color-white);
  font-size: 12px;
  border-style: none;
  border-radius: 6px;
  text-transform: uppercase;
  cursor: pointer;
}

.button:disabled {
  background: var(--color-orange-secondary);
  color: rgb(var(--color-white-rgb) / 50%);
  pointer-events: none;
}

.select {
  width: 355px;
  position: relative;
  font-size: 14px;
  color: var(--color-dark-purple);
}

@media (width <= 600px) {
  .select {
    width: 300px;
  }
}

@media (width <= 500px) {
  .select {
    width: 200px;
  }
}

.openButton {
  padding: 2px 30px 2px 6px;
  margin: 0;
  border-top: 1px solid var(--color-dark-purple);
  border-right: 1px solid var(--color-dark-purple);
  border-left: 1px solid var(--color-dark-purple);
  border-bottom: 1px solid var(--color-dark-purple);
  border-radius: 2px 2px 0 0;
  height: 22px;
  width: 100%;
  text-align: start;
  font-size: 14px;
  background:
    url('chevron_down_dark.svg') no-repeat top 8px right 9px,
    var(--color-dropdown);
  cursor: pointer;
  color: rgb(var(--color-grey-rgb) / 75%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selectedAccount {
  color: inherit;
}

.closeButton {
  composes: openButton;
  background:
    url('chevron_up_dark.svg') no-repeat top 8px right 9px,
    var(--color-dropdown);
  border-bottom: none;
}

.options {
  list-style: none;
  padding: 0 30px 0 6px;
  position: absolute;
  left: 0;
  right: 0;
  border-style: solid;
  border-width: 0 1px 1px;
  border-color: var(--color-dark-purple);
  border-radius: 0 0 2px 2px;
  background: var(--color-dropdown);
}

.option {
  box-sizing: border-box;
  height: 33px;
  display: flex;
  align-items: center;
  border-top: 2px dotted var(--color-medium-blue);
  padding: 2px 0;
  cursor: pointer;
}

.option:hover,
.option:focus {
  background: content-box rgb(var(--color-orange-rgb) / 20%);
}

.option p {
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
