.navContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgb(var(--color-header-below) / 90%) no-repeat;
}

.navbar {
  max-width: 766px;
  width: 90%;
  display: flex;
  align-items: flex-end;
  gap: 18px;
}

.navLink {
  text-decoration: none;
  width: 374px;
  display: flex;
  flex-direction: column;
  color: var(--color-dark-purple);
  text-transform: uppercase;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 17px;
  height: 28px;
  background-color: var(--color-blue-navlink);
  box-shadow: inset 0 -1px 6px 0 rgb(0 0 0 / 15%);
  border: none;
  border-radius: 3px 3px 0 0;
  cursor: pointer;
}

.navLinkActive {
  composes: navLink;
  font-size: 18px;
  letter-spacing: 0.16px;
  line-height: 20px;
  height: 35px;
  background-color: var(--color-silver-blue);
}

.signUnderline {
  height: 2px;
  width: 130px;
  background-color: var(--color-pink);
  margin-bottom: 1px;
}

.verifyUnderline {
  height: 2px;
  width: 130px;
  background-color: var(--color-green);
  margin-bottom: 1px;
}

.activeUnderline {
  height: 4px;
}
