.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 100px;
}

.textWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;
}

.verificationText {
  display: flex;
  justify-content: center;
  color: green;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.11px;
  width: 80px;
  padding-right: 35px;
  background: url('../../images/icon_ok.svg') no-repeat right 30%;
}

.verificationIcon {
  display: flex;
  justify-content: flex-start;
  width: 70%;
}

.text {
  display: flex;
  justify-content: flex-start;
  color: var(--color-dark-purple);
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  font-weight: bold;
  word-break: break-all;
  width: 550px;
  flex-wrap: wrap;
}

.credentialContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  max-width: 550px;
  width: 95%;
}

.credentialsWrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-dark-purple);
  word-break: normal;
  margin-top: 2px;
  width: 100%;
  border-bottom: dotted 2px #4d6b85;
}

.anchor {
  color: var(--color-medium-blue);
  background: url('icon_link.svg') no-repeat right 40%;
  padding-right: 20px;
  margin-right: 50%;
}

.subscan {
  composes: anchor;
  padding-left: 10px;
  margin-right: auto;
}

.errorText {
  composes: text;
  color: var(--color-orange);
  word-break: normal;
}

.endpointsWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: var(--color-dark-purple);
  font-size: 14px;
  gap: 20px;
  line-height: 22px;
  letter-spacing: 0.11px;
  word-break: normal;
  max-width: 550px;
  width: 100%;
}

.title {
  display: flex;
  justify-content: flex-start;
  color: var(--color-dark-purple);
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.11px;
  width: 80px;
  text-align: left;
}

.errorTitle {
  composes: title;
  color: var(--color-orange);
}
