.grid {
  display: grid;
  box-sizing: border-box;
  grid-template: 'arrow list' / 1.5em auto;
  column-gap: 5px;
  padding-top: 5px;
  border-bottom: dotted 2px var(--color-medium-blue);
}

.list {
  composes: list from '../Files/Files.module.css';
  grid-area: list;
  padding-top: 0;
}

.listItem {
  border-bottom: dotted 2px var(--color-medium-blue);
}

.listItem:last-child {
  border: none;
  height: 35px;
}

.arrowIcon {
  grid-area: arrow;
  margin-top: 5px;
  margin-left: 5px;
  background: url('arrow_nested.svg') no-repeat;
}

.credentialContainer {
  composes: file from '../Files/Files.module.css';
  gap: 5px;
  border: none;
}

.editing {
  background-color: rgb(var(--color-blue-focus-rgb) / 20%);
}

.error {
  background-color: rgb(var(--color-pink-rgb) / 20%);
}

.credentialLabel {
  composes: fileName from '../Files/Files.module.css';
  background: url('credential.svg') no-repeat;
}

.inputContainer {
  max-width: 330px;
  width: 95%;
  display: flex;
  justify-content: flex-start;
  color: var(--color-dark-purple);
  height: 23px;
  border: none;
  margin: 0;
  background-color: white;
  position: relative;
}

.input {
  width: 100%;
}

.inputError {
  position: absolute;
  left: 0;
  right: 0;
  top: 23px;
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0.09px;
  line-height: 18px;
  color: white;
  margin: 0;
  padding-left: 20px;
  background:
    url('error_icon.svg') no-repeat 1% 40%/4%,
    var(--color-dark-purple);
}

.name {
  max-width: 330px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 53%;
  color: var(--color-dark-purple);
  height: 23px;
  border: none;
}

.editingInfo {
  margin: 0;
  color: var(--color-pure-red);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 80%;
  font-size: 12px;
  line-height: 13px;
}

.editContainer {
  display: flex;
  max-width: 150px;
  gap: 10px;
  width: 40%;
  justify-content: flex-end;
  align-items: flex-end;
}

@media (width <= 500px) {
  .credentialLabel,
  .editingInfo,
  .inputError,
  .name {
    font-size: 10px;
  }
}

.editButton {
  composes: iconButton from '../Files/Files.module.css';
  background: url('edit.svg') no-repeat center right/110%;
}

.deleteButton {
  composes: deleteButton from '../Files/Files.module.css';
}
