.container {
  width: 100%;
  display: flex;
  place-content: center;
  background: var(--color-silver-blue);
  flex: 1;
}

.content {
  max-width: 766px;
  width: 90%;
  letter-spacing: 0.1px;
  line-height: 22px;
  color: var(--color-dark-purple);
  font-size: 14px;
  padding: 36px 0 100px;
}

.heading {
  font-size: 24px;
  margin: 0 0 60px;
}

.section {
  margin: 16px 0 0;
}

.line {
  margin: 0;
}

.anchor {
  text-decoration: none;
}
